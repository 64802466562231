import React from "react"
import { Link } from "gatsby"
import styled from "@emotion/styled"
import { css } from "@emotion/core"
import BgImage from "../../images/adoption.jpg"
import Layout from "../../components/layoutSurpriseCustom"
import PageHeaders from "../../components/pageHeaders"
import SEO from "../../components/seo"

const ParagraphText = styled.p`
  font-size: 1.25rem;
`

const HeaderText = styled.h1`
  margin-top: 2rem;
  font-size: 1.5rem;
`

const IndexPage = () => (
  <Layout ImgSrc={BgImage} MainText="Adoption and Foster Care">
    <SEO title="Adoption and Foster Care" />
    <PageHeaders
      css={css`
        display: flex;
      `}
    >
      Adoption and Foster Care
    </PageHeaders>
    <HeaderText></HeaderText>
    <HeaderText></HeaderText>
    <ParagraphText>
      The mission of this ministry is to bring glory and honor to God in all we
      do whether in word or deed, and to do it all In His Name. The vision is a
      world in which every child has a family that loves them and knows Jesus as
      their Lord and Savior. We are actively partnering with faith-based
      community and civic organizations promoting foster, adoptive, and family
      services to help them obtain a better life through mentorship, material
      assistance, prayer support, and referrals.
      <br />
      <br />
      If you would like to learn more about how Cross Church Foster Care
      Ministry can serve you and your family, or if you would like to serve in
      the ministry, please contact Catherine Alonge at{" "}
      <a href="mailto:sncalonge@gmail.com">sncalonge@gmail.com</a>, or click one
      of the links below.
    </ParagraphText>
    <PageHeaders
      css={css`
        display: flex;
        margin-top: 6rem;
      `}
    >
      Foster Care Ministry Opportunities
    </PageHeaders>
    <HeaderText>
      Assistance for Foster Care Families and Agencies we partner with:
    </HeaderText>
    <HeaderText></HeaderText>
    <ParagraphText>
      <a href="http://www.abcs.org/">ABCS</a> – (602) 346-2300
      <br />
      <br />
      <a href="http://www.ocjkids.org/">OCJ Kids</a> – (602) 439-2172
      <br />
      <br />
      <a href="http://www.cfcare.org/">Christian Family Care</a> – (602)
      234-1935
      <br />
      <br />
      <a href="http://www.arizonansforchildren.org/page.php">
        Arizonans For Children
      </a>{" "}
      – (480) 838-0085
    </ParagraphText>
    <HeaderText>Helpful Resources:</HeaderText>
    <HeaderText></HeaderText>
    <ParagraphText>
      <a href="http://www.fosterparents.com/">Online Training</a>
      <br />
      <br />
      <a href="mailto:aim2bee@live.com">Tax Information for adoption credit</a>
      <br />
      <br />
      <a href="http://www.azkidsconsortium.com/">AZ Kids Conortium website</a>
      <br />
      <br />
      <a href="http://www.affcf.org/">
        AZ Friends of Foster Children Foundation
      </a>
      <br />
      <br />
      <a href="http://www.childrensheartgallery.org/">
        Children's Heart Gallery
      </a>
      <br />
      <br />
      <a href="http://www.azdes.gov/az_adoption">
        Division of Children, Youth, and Family
      </a>
      <br />
      <br />
      <a href="http://www.abcs.org/foster-care/no-child-waiting-coalition-2">
        Various Support Groups
      </a>
      <br />
      <br />
      <a href="http://www.nochildwaiting.org/about">No Child Waiting</a>
      <br />
      <br />
      <a href="http://www.az127.org/about">Arizona 1.27</a>
    </ParagraphText>
    <PageHeaders
      css={css`
        display: flex;
        margin-top: 6rem;
      `}
    >
      Sign-Up Below
    </PageHeaders>
    <HeaderText></HeaderText>
    <iframe
      src="https://cccares.formstack.com/forms/nextsteps"
      title="Connect - Cross Church"
      width="100%"
      height="1200px"
      frameBorder="0"
    ></iframe>
  </Layout>
)

export default IndexPage
